var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-icon" }, [
    _c(
      "span",
      [
        _vm._l(_vm.active_range, function(value, index) {
          return _c("i", {
            key: index,
            class: "active fas fa-chevron-right " + _vm.icon_size,
            attrs: { props: "" }
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.inactive_range, function(value, index) {
          return _c("i", {
            key: index + _vm.val,
            class: "inactive fas fa-chevron-right " + _vm.icon_size,
            attrs: { props: "" }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }